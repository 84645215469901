import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
// import Headers from './header';
import Header from './header';
import FileUpload from '../module/uploadFile';
// import { useState } from 'react';
import axios from 'axios';
import Pagination from '../common/pagination';

// const imageSrc = require('../logo.jpeg');

class ProductCard extends Component {
  render() {

    const { _id, newOrders, pickedOrders, sav } = this.props;
    // const [showPopup, setShowPopup] = false;


    return (
      <NavLink className="navlink" to={"/store/" + _id}>
        <div className='py-2 my-0'>

          <div className="col card1 d-flex flex-wrap am-card justify-content-center">
            <div className='py-2 mb-1 text-center'>
              {/* <h5>{name}</h5> */}
              <h6>{_id}</h6>
            </div>
            <div className="d-flex mb-2 mx-auto">
              <div className="px-3 mx-2 card new-bg">
                <span>{newOrders}</span>
              </div>
              <div className="px-3 mx-2 card picked-bg">
                <span>{pickedOrders}</span>
              </div>
              <div className="px-3 mx-2 card sav-bg">
                <span>{sav}</span>
              </div>

            </div>
          </div>

        </div>
      </NavLink>
    );
  }
}

ProductCard.propTypes = {
  newOrders: PropTypes.number.isRequired,
  pickedOrders: PropTypes.number.isRequired,
  sav: PropTypes.number.isRequired,
  en_num_doc: PropTypes.string.isRequired,
};

class InventoryDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: "",
      setShowPopup: "",
      dashboardlist: [],
      page: 1,
      setPage: "",
      setStores: "",
      loader: true,
      totalPage: 0
    };
    this.getStores();
  }
  pageChange = (e) => {
    console.log(e);
    let p = e.target.attributes.getNamedItem("data-page").value;
    this.setState({
      page: Number(p)
    });

  };
  handleUploadClick = async () => {
    this.setState({
      showPopup: true,
      setShowPopup: true
    })
    // this.handleUploadFiles(files);
    // const files = // Get the files you want to upload
    this.refs.fileUploader.click();


  };
  handleUploadFiles = async (files) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      console.log(files);

      const response =
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/store/csv/import`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
            }
          }
        );
      console.log(response.status);
      if (response.status === 200) {
        alert('File imported successfully')
        this.getStores();
      }
    } catch (e) {
      console.log(e);

    }
  }

  handlePreparationUploadFiles = async (files) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      console.log(files);

      const response =
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/preparation/file/import`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
            }
          }
        );
      console.log(response.status);
      if (response.status === 200) {
        alert('Preparation file imported successfully')
      }
    } catch (e) {
      console.log(e);

    }
  }

  getStores = async (search = false) => {

    try {
      let page = this.state.page;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/store/getstore-count/${page}?search=${(search) ? search : ''}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
          }
        }
      );
      localStorage.setItem("searchDataValue", JSON.stringify(search));
      console.log(response.data.data);
      this.setState({
        dashboardlist: response.data.data,
        totalPage: response.data.totalPage,
        loader: false
      })
      let data = response.data;

      console.log(data.totalPage);
      // this.state.setStores({
      //   stores: data.data,
      //   totalPage: data.totalPage,
      //   loader: false,
      // });

    }
    catch (err) {
      console.log(err);
    }
  };
  // componentDidMount = () => {

  //   this.getStores();
  // }
  componentDidMount() {
    this.getStores();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getStores();
    }
  }

  render() {

    return (
      <div className='w-100'>
        <Header />
        <div className="d-flex justify-content-end  my-3 mx-5">
          <div>
            {this.state.showPopup === true && (
              <div id="pop-up">
                <FileUpload />
              </div>
            )}  {/* <img src={imageSrc} alt='logo' /> */}
          </div>
          <div className='d-flex justify-content-end'>
            <button onClick={() => { this.refs.PrepareFileUploader.click(); }} className="btn btn-bg mx-2 border rounded">Preparation Commandes</button>
            <input type="file" id="preparation" ref="PrepareFileUploader" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style={{ display: "none" }} onChange={(e) => { this.handlePreparationUploadFiles(e.target.files) }} />
            {/* <input type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={(e) => this.handleUploadFiles(e.target.files)} /> */}
            {/* <button onClick={() => document.getElementById('file').click()}>Upload</button> */}

            <button onClick={this.handleUploadClick} className="btn btn-bg mx-2 border rounded">CMD non soldées</button>
            <input type="file" id="file" ref="fileUploader" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style={{ display: "none" }} onChange={(e) => { this.handleUploadFiles(e.target.files) }} />
            {/* <button className="btn btn-bg me-2 border rounded">Trouver</button> */}
            
            {/* EXPORT FILE */}
            <NavLink className="navlink" to={"/export-scan/"}>
                <button type="button" className="btn picked-bg mx-2 border rounded text-bold ">  Export</button>
            </NavLink>

            <input type='search' placeholder='Trouver' className='search-input btn me-2 border rounded-pill py-2  text-black ' onChange={(e) => { this.getStores(e.target.value) }} name='SearchStore'></input>
          </div>
        </div>
        {this.state.dashboardlist?.length === 0 ? ((this.state.loader) ?
          <div className='text-center p-4'>
            <div className="loader1"><span></span><span></span><span></span><span></span><span></span></div>
          </div> :
          <div className="d-flex">
            <p className="mx-auto">Aucun Enregistrement Trouvé.</p></div>
        ) : (
          <div className="d-flex flex-wrap mx-5 mb-5">
            {this.state.dashboardlist?.map((product, index) => (
              <div key={product?.cl_code || "test" + index} className="col-lg-2 col-md-3 col-sm-4 col-6 tsw-list-card card">
                <ProductCard
                  _id={product.cl_code}
                  name={product.ac_raison_sociale}
                  newOrders={product.new}
                  pickedOrders={(product?.picked) ? product?.picked : 0}
                  sav={(product?.sav) ? product?.sav : 0}
                  en_num_doc={product.en_num_doc}
                />
                {(index + 1) % 5 === 0 && <div className="w-100"></div>}
              </div>
            ))}
          </div>
        )}

        {/* <div className="d-flex flex-wrap mx-5">
          {this.state.dashboardlist.map((product, index) => (
            <div key={product.cl_code} className="col-lg-2 col-md-3 col-sm-4 col-6 tsw-list-card card">
              <ProductCard
                _id={product.cl_code}
                name={product.ac_raison_sociale}
                newOrders={product.count[0].count}
                pickedOrders={(product?.count[1]?.count) ? product?.count[1]?.count : 0}
                sav={(product?.count[2]?.count) ? product?.count[2]?.count : 0}
              />
              {(index + 1) % 5 === 0 && <div className="w-100"></div>}
            </div>
          ))}
        </div> */}
        {this.state.dashboardlist?.length === 0 ? (
          ""
        ) : (
          <div className='mt-2 pagination justify-content-center d-none'>
            {/* <div className='page-item'> */}
            <Pagination
              page={this.state.page}
              pageChange={this.pageChange}
              totalPage={this.state.totalPage}
            />
            {/* </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default InventoryDashboard;
