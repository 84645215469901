import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import Header from './header';
import axios from 'axios';

class ExporScnned extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PickedOrder: [],
            startDate: "",
            endDate: "",
            errorMessage: "",
            selectedHeaders: ['en_num_doc'],
        };
    }

    handleSubmit = async () => {
        const { startDate, endDate } = this.state;
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

        // Validate Start Date
        if(startDate === ""){
            this.setState({ errorMessage: "Start date required." });
            return; // Stop the submit if the validation fails
        }
        if(endDate === ""){
            this.setState({ errorMessage: "End date required." });
            return; // Stop the submit if the validation fails
        }
        if (startDate > today) {
            this.setState({ errorMessage: "Start date cannot be greater than today." });
            return; // Stop the submit if the validation fails
        }

        // Validate End Date
        if (endDate > today) {
            this.setState({ errorMessage: "End date cannot be greater than today." });
            return; // Stop the submit if the validation fails
        }

        if (endDate < startDate && startDate !== "") {
            this.setState({ errorMessage: "End date cannot be before the start date." });
            return; // Stop the submit if the validation fails
        }

        // Reset error message if all validations pass
        this.setState({ errorMessage: "" });

        let Data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/export?search=`,
                Data,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );
            console.log(response.data.data);
            if(response.data.status === "Success"){
                this.generateXLS(response.data.data);
                console.log(response.data.data);
                console.log("CSV");
            }else{
                this.setState({ errorMessage: "Something went wrong" });
            }
        } catch (error) {
            console.error("Error in API request", error);
        }
    };

    generateCSV = (data) => {
        // Select only the fields you want to export from each object based on the selectedHeaders
        const headers = this.state.selectedHeaders;

        // Convert the array of objects into CSV format
        const csvRows = [];
        // Add the headers row
        csvRows.push(headers.join(','));

        // Add data rows
        data.forEach(row => {
            csvRows.push(
                headers.map(fieldName => 
                    JSON.stringify(row[fieldName], (key, value) => value === null ? '' : value)
                ).join(',')
            );
        });

        // Create a Blob from the CSV string
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        // Create a download link and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'export.csv'); // File name for download
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    
    generateXLS = (data) => {
        // Select only the fields you want to export from each object based on the selectedHeaders
        // const headers = this.state.selectedHeaders;
        const headers = [
            "Send Date", 
            "en_num_doc", 
            "cl_code", 
            "en_num_ext", 
            "ac_raison_sociale", 
            "Tracking Number"
        ];
    
    
        // Create a worksheet
        // const worksheetData = data.map(row => 
        //     headers.map(fieldName => 
        //         row[fieldName] === null ? '' : row[fieldName]
        //     )
        // );

        // Map data fields accordingly
        const worksheetData = data.map(row => [
            row.scannedDate || '', 
            row.en_num_doc || '',  
            row.cl_code || '',      
            row.en_num_ext || '',   
            row.ac_raison_sociale || '',
            row.awb || ''         
        ]);
        
        console.log("xls");
        console.log(worksheetData);
        // Add headers as the first row
        worksheetData.unshift(headers);
    
        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    
        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // 'Sheet1' is the sheet name
    
        // Generate XLS file and trigger download
        const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
        const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel;charset=utf-8' });
    
        // Create a download link and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'export.xls'); // File name for download
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    render() {
        return (
            <div>
                <Header />
                <div className='mx-5'>
                    <div className="tile mb-4 p-2">
                        <div className="scan_report_generate_container">
                            <div className="invalid report_err"></div>
                        </div>
                    </div>

                    {/* scan area */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-body">
                                    <div className="scanner-area">
                                                <div className="error" style={{ color: 'red' }}>
                                                    {this.state.errorMessage}
                                                </div>
                                        <div className='align-items-center amira-scanner-block d-flex flex-column flex-lg-row justify-content-center'>
                                            <div className="d-flex gap-3 scan-input-action align-items-center">
                                                <div className="success" style={{ color: 'green' }}></div>
                                                <span>From Date:</span>
                                                <input
                                                    type="date"
                                                    className='p-1'
                                                    name="fromdate"
                                                    id="str_text1"
                                                    value={this.state.startDate}
                                                    onChange={(e) => this.setState({ startDate: e.target.value })}
                                                />
                                            </div>
                                            <div className="d-flex gap-3 scan-input-action align-items-center">
                                                {/* <div className="error" style={{ color: 'red' }}>
                                                    {this.state.errorMessage}
                                                </div> */}
                                                <div className="success" style={{ color: 'green' }}></div>
                                                <span>To Date:</span>
                                                <input
                                                    type="date"
                                                    className='p-1'
                                                    name="todate"
                                                    id="str_text"
                                                    value={this.state.endDate}
                                                    onChange={(e) => this.setState({ endDate: e.target.value })}
                                                />
                                            </div>
                                            <button
                                                className="js-add-post btn bg-primary me-2 border rounded text-white px-5 py-auto"
                                                id="start-scan"
                                                onClick={this.handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                className="js-add-post btn bg-danger me-2 border rounded text-white px-5 py-auto"
                                                id="start-scan"
                                                onClick={() => { this.setState({ startDate: "", endDate: "", errorMessage: "" }) }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExporScnned;
