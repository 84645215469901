import React, { Component } from 'react';
import Header from './header';
import axios from 'axios';
import Pagination from "../common/pagination";
import bwipjs from 'bwip-js';


class Scan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storelist: [],
            PickedOrder: [],
            page: 1,
            setPage: "",
            setStores: "",
            store_name: "",
            store_code: "",
            scan_text: "Lancer la numérisation",
            scan_start: false,
            scannedData: [],
            scannedSuccessID: [],
            awb: "",
            orderStatus: "Dispatched",
            editTrackingCode: "",
            inputTrackingCode: "",
            editTrackingCodeHide: false,
            editingTrackingCodes: {}
        }
        console.log(props.params);
    }

    handleScanUpdate = async (e) => {
        try {
            if (this.state.scannedSuccessID.length > 0) {
                let bodyData = {
                    ids: this.state.scannedSuccessID,
                    etat_lib: this.state.orderStatus,
                    awb: this.state.awb
                }
                // console.log(this.state.scannedSuccessID);
                // console.log("bodyData below");
                // console.log(bodyData);

                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/store/update-scanned`,
                    bodyData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                        },
                    }
                );
                if (response.data.status == "Success") {
                    alert("Success");
                    let obj = this.state.scannedData;
                    obj.map((item,index) => {
                        if(item.en_num_doc != "" || item.en_num_ext != ""){
                            obj[index].awb = this.state.awb;
                        }

                    })
                    this.setState({
                        scannedSuccessID: [],
                        awb: this.state.awb,
                        scannedData: obj
                    });
                    
                } else {
                    alert("Failed please try again later");
                    // console.log(response.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleScanStartStop = async (e) => {
        let status = this.state.scan_start;
        let scanIDs = this.state.scannedSuccessID;
        if (status) {
            if (scanIDs.length > 0) {

            }
            this.setState({
                scan_start: !status,
                scan_text: "Lancer la numérisation"
            }, () => {
                this.inputEl.blur();
            })
        } else {
            this.setState({
                scan_start: !status,
                scan_text: "Arrêter la numérisation"
            }, () => {
                this.inputEl.focus();
            })
        }

    }

    timeoutReference = null;
    timeout = 1000; // 1 second default timeout

    handleDoneTyping = async (el) => {
        // Custom logic to be executed when typing is done
        console.log('Typing done:', el.value);
        let Data = {
            code: this.state.store_code,
            scannedValue: el.value
        }
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/store/get-scanned`,
            Data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                },
            }
        );
        console.log(response);
        let obj = this.state.scannedData;
        let scannedSuccessID = this.state.scannedSuccessID;
        if (response.data.status == "Failure") {
            console.log("Failde");
            obj.push({ en_num_doc: el.value, en_num_ext: el.value, status: 0 })
        } else {
            let result = response.data.data;
            console.log("result");
            console.log(response.data);
            obj.push({ en_num_doc: result.en_num_doc, en_num_ext: result.en_num_ext, awb: result.awb, _id: result._id, status: 1 });
            scannedSuccessID.push(result._id);

            console.log("REs-id + : "+ result._id);
            // Filter out the scanned item from PickedOrder
            let updatedPickedOrder = this.state.PickedOrder.filter(
                item => item.en_num_doc !== result.en_num_doc
            );
            this.setState({ PickedOrder: updatedPickedOrder });
            console.log("updatedPick");
            console.log(updatedPickedOrder);
        }
        this.setState({
            scannedData: obj,
            scannedSuccessID: scannedSuccessID
        }, () => {
            console.log(this.state.scannedData);
            el.value = '';
        })
    };

    handleKeyUp = (e) => {
        if (e.type === 'keyup' && e.keyCode !== 8) return;

        if (this.timeoutReference) clearTimeout(this.timeoutReference);
        this.timeoutReference = setTimeout(() => {
            this.handleDoneTyping(this.inputEl);
        }, this.timeout);
    };

    handleBlur = () => {
        this.handleDoneTyping(this.inputEl);
    };

    getStores = async (search = false) => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            console.log(currentPath);
            let query_string = '?search=';
            if (search) {
                query_string += search
            }
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}/${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data?.data);
            this.setState({
                storelist: response.data.data,
                // totalPage: response.data.totalPage,
                store_code: productId,
                store_name: response.data.data[0]?.ac_raison_sociale,
                editTrackingCode: response.data.data[0]?.awb
            })

            //   setCompanies({
            //     companies: data.data,
            //     totalPage: data.totalPage,
            //     loader: false,
            //   });
        } catch (err) {
            console.log(err);
        }
    };

    getPickedStores = async (search = "picked") => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            console.log(currentPath);
            let query_string = '?search=';
            if (search) {
                query_string += search
            }
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}/${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );
            let query_string2 = '?search=sav';
            const response2 = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}/${query_string2}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data.data[0]?.cl_code);
             // Filter out items where isScanned is true
            console.log("pickdata");
            console.log(response.data.data);
            const filteredData = response.data.data.filter(item => !item.isScanned);
            const filteredData2 = response2.data.data.filter(item => !item.isScanned);
            console.log("Filtered Data:", filteredData);

            this.setState({
                PickedOrder: [...filteredData, ...filteredData2],
                // PickedOrder: response.data.data,
                totalPage: response.data.totalPage,
            })
        } catch (err) {
            console.log(err);
        }
    };
    pageChange = (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        this.setState({
            page: Number(p)
        }, () => {
            this.getPickedStores();
        });

    };

    componentDidMount() {
        this.getStores();
        this.getPickedStores();
        this.inputEl.addEventListener('keyup', this.handleKeyUp);
        // this.inputEl.addEventListener('blur', this.handleBlur);
    }
    componentWillUnmount() {
        this.inputEl.removeEventListener('keyup', this.handleKeyUp);
        // this.inputEl.removeEventListener('blur', this.handleBlur);
    }

    // GET SVG [FOR Datamatrix]
    getSVG = (value) => {
        let canvas = document.createElement("canvas");
        bwipjs.toCanvas(canvas, {
            bcid: "datamatrix", // Barcode type
            text: value, // Text to encode
            scale: 2, // 3x scaling factor
            height: 8, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center" // Always good to set this
        });

        return <div> <img src={canvas.toDataURL("image/png")} alt={value}></img> </div>
    }

    handleSpanTrackCodeEdit = (awb, rowId) => {
        console.log("AWB clicked:", awb);
        //     this.setState({ editTrackingCodeHide: true, editTrackingCode: awb, inputTrackingCode: awb });
        this.setState(prevState => ({
            editingTrackingCodes: {
                ...prevState.editingTrackingCodes,
                [rowId]: {
                    editing: true,
                    awb: awb,
                    inputTrackingCode: awb
                }
            }
        }));
    };

    handleInputChangeTrackingCode = (e, rowId) => {
        const value = e.target.value;
        this.setState(prevState => ({
            editingTrackingCodes: {
                ...prevState.editingTrackingCodes,
                [rowId]: {
                    ...prevState.editingTrackingCodes[rowId],
                    inputTrackingCode: value
                }
            }
        }));
    };

    handleUpdateAPITrackingCode = async (e, item, rowId) => {
        // console.log("item store_code");
        // console.log(this.state.store_code);
        // console.log("item storelist below");
        // console.log(this.state.storelist);
        // console.log(this.state.scannedSuccessID);
        const { inputTrackingCode } = this.state.editingTrackingCodes[rowId];

        const requestBody = {
            ids: item._id,
            cl_code: this.state.store_code,
            awb: inputTrackingCode,

        };
        // console.log("Request body got below");
        try {
            // console.log("Request Body:", requestBody);

            // Send the PUT request using axios
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/store/single-update-tracking`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            // Log the response
            if (response.data.status === "Success") {
                alert("Success");
                this.setState(prevState => ({
                    scannedData: prevState.scannedData.map(row =>
                        row._id === item._id ? { ...row, awb: inputTrackingCode } : row
                    ),

                    editingTrackingCodes: {
                        ...prevState.editingTrackingCodes,
                        [rowId]: {
                            ...prevState.editingTrackingCodes[rowId],
                            editing: false,
                            awb: inputTrackingCode
                        }
                    }
                }));
            } else {
                console.error("API Error: Operation not successful");
            }
            // Update the state to exit edit mode
            // this.setState({ editState: "", awb: this.state.inputTrackingCode });
        } catch (error) {
            // Log any errors
            // console.error("API Update Error:", error);
            // Optionally, handle the error in the UI
            this.setState({ errorMessage: "Failed to update tracking information" });
        }

    }

    render() {

        return (
            <div>
                <Header />
                <div className='mx-5'>
                    <div className="app-title">
                        <div>
                            <div>
                                <h6 className="mt-3 ms-2">Code client : {this.state.store_code}</h6>
                                <h6 className="mt-3 ms-2">Client : {this.state.store_name}</h6>
                            </div>
                            {/* <h1>
                                <i className="app-menu__icon fa fa-qrcode"></i>Scan
                            </h1> */}
                        </div>
                        <ul className="app-breadcrumb breadcrumb side">
                            {/* Use appropriate React syntax to render the shortcode */}
                            {/* {do_shortcode('[tsw-panel-change]')} */}
                        </ul>
                    </div>
                    {/* scan report */}
                    <div className="tile mb-4 p-2">
                        <div className="scan_report_generate_container">
                            <div className="invalid report_err"></div>
                        </div>
                    </div>

                    {/* scan area */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-body">
                                    <div className="scanner-area">
                                        <div className='amira-scanner-block'>
                                            <div className="scan-input-action">
                                                <div className="error" style={{ color: 'red' }}></div>
                                                <div className="success" style={{ color: 'green' }}></div>
                                                <input type="text" name="awb" id="str_text" ref={(el) => (this.inputEl = el)} onChange={this.handleKeyUp} disabled={this.state.scan_start ? false : true} />
                                            </div>
                                            <button className="js-add-post btn bg-secondary me-2 border rounded text-white px-5 py-auto" id="start-scan" onClick={this.handleScanStartStop}>
                                                {(!this.state.scan_start) ? this.state.scan_text : "Arrêter la numérisation"}
                                            </button>
                                        </div>
                                        {(this.state.scan_start == false && this.state.scannedSuccessID.length > 0) ?
                                            <div className={`amira-scan-update-block1 d-flex gap-2`} >
                                                <input type="text" placeholder='AWB number....' name="awb" id="awb" onChange={(e) => { this.setState({ awb: e.target.value }) }} value={this.state.awb} />
                                                <button className="js-add-post btn bg-secondary me-2 border rounded text-white px-5 py-auto" id="scanUpdates" onClick={this.handleScanUpdate}>
                                                    Submit
                                                </button>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-body">
                                    <div className="text-uploded d-flex justify-content-between">
                                        <h1> Commandes scannées s</h1>
                                        <div id="uploded_pack" className="scanned_summery">
                                            <div>Nombre total de colis scannes :</div>
                                            <div className="count">{this.state.scannedData.length}</div>
                                        </div>
                                    </div>

                                    <table className="table table-hover table-bordered" id="sampleTable">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>Num cmd Danielli</th>
                                                <th>Num cmd Client</th>
                                                <th>Num cmd Client</th>
                                                <th>Tracking</th>
                                                {/* <th>DATE D'ENVOI</th> */}
                                                {/* <th>
                                                    STATUT
                                                    <a className="bulk_status_update">
                                                        <i className="fa fa-refresh" aria-hidden="true"></i>
                                                    </a>
                                                </th>
                                                <th>Transporteur</th> */}
                                            </tr>
                                        </thead>
                                        <tbody id="package_detail">
                                            {this.state.scannedData.length < 1 ?
                                                <tr>
                                                    <td className='text-center' colSpan={3}>Aucun enregistrement disponible</td>
                                                </tr>
                                                :
                                                this.state.scannedData.map((item, index) => (

                                                    <tr className='text-center' key={"aa" + index}>

                                                        <td><span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_doc}</span></td>
                                                        <td><span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_ext}</span></td>
                                                        <td><span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_ext}</span></td>
                                                        <td>
                                                            {/* {
                                                                this.state.editingTrackingCodes[item._id]?.editing ? (
                                                                    <div className='showInputForTrackingCode'>
                                                                        <input
                                                                            type="text"
                                                                            value={this.state.editingTrackingCodes[item._id].inputTrackingCode || ""}
                                                                            onChange={(e) => this.handleInputChangeTrackingCode(e, item._id)}
                                                                        />
                                                                        <button
                                                                            className="js-add-post btn bg-secondary me-2 border rounded text-white px-5 py-auto"
                                                                            onClick={(e) => this.handleUpdateAPITrackingCode(e, item, item._id)}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <span
                                                                        className={item.status === 1 ? 'picked-bg' : 'new-bg'}
                                                                        onClick={() => this.handleSpanTrackCodeEdit(item.awb, item._id)}
                                                                    >
                                                                        {item.awb || "+"} 
                                                                    </span>
                                                                )
                                                            } */}
                                                             <span   className={item.status === 1 ? 'picked-bg' : 'new-bg'}>
                                                                        {item.awb || "-"} 
                                                                    </span>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>{/* Pagination elements */}</tfoot>
                                    </table>
                                </div>
                                <div className="text-uploded d-flex justify-content-between pt-4">
                                    <h2>Commandes pretes a etre expédiés</h2>
                                    {/* <div id="uploded_pack" className="scanned_summery"> */}
                                    {/* <div>Nombre total de colis scannes :</div> */}
                                    <div className="count">{this.state.scannedData.length}</div>
                                    {/* </div> */}
                                </div>
                                <table className="table table-hover table-bordered">
                                    <thead className="thead-dark mx-5">
                                        <tr>
                                            <th className="text-center">Dashboard</th>
                                            <th className="text-center">Date commande</th>
                                            <th className="text-center">Num cmd Danielli</th>
                                            <th className="text-center">Datamatrix</th>
                                            <th className="text-center">Num cmd Client</th>
                                            <th className="text-center">Date livraison souhaitée</th>
                                            <th className="text-center">Etat</th>
                                            <th className="text-center">Opération</th>
                                            <th className="text-center">Reservation</th>
                                            <th className="text-center">Marque</th>
                                            {/* <th className="text-center">Tracking</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.PickedOrder.length < 1 ?
                                            <tr>
                                                <td className='text-center' colSpan={9}>Aucun enregistrement disponible</td>
                                            </tr>
                                            :
                                            this.state.PickedOrder.map((product) => (
                                                <tr className={`${(product.archive) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""}>
                                                    <td className="text-center">
                                                        {new Date(product.createdAt).toLocaleDateString() + ' '}
                                                        {new Date(product.createdAt).toLocaleTimeString()}
                                                    </td>
                                                    <td className="text-center">
                                                        {/* {product.en_date_doc} */}
                                                        {product.en_date_doc ? (
                                                            new Date(product.en_date_doc).toLocaleDateString() + ' ' + new Date(product.en_date_doc).toLocaleTimeString()
                                                        ): "-" }
                                                    </td>
                                                    <td className="text-center">{product.en_num_doc}</td>
                                                    <td className="text-center">
                                                        {this.getSVG(product.en_num_doc)}
                                                    </td>
                                                    <td className="text-center">{product.en_num_ext}</td>
                                                    <td className="text-center">
                                                        {/* {product.en_date2} */}
                                                        {new Date(product.en_date2).toLocaleDateString() + ' '}
                                                        {new Date(product.en_date2).toLocaleTimeString()}
                                                    </td>
                                                    <td className="text-center">
                                                        {(product.etat_lib != '') ? product.etat_lib : ""}
                                                    </td>
                                                    <td className="text-center">{product.op_libel ?? '-'}</td>
                                                    <td className="text-center">{product.res ?? '-'}</td>
                                                    <td className="text-center">{product.marque_logotee ?? '-'}</td>
                                                    {/* <td className="text-center">{product.awb}</td> */}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <div className='mt-2 pagination justify-content-center'>
                                    {/* <div className='page-item'> */}
                                    <Pagination
                                        page={this.state.page}
                                        pageChange={this.pageChange}
                                        totalPage={this.state.totalPage}
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Scan;
