import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const imageSrc = require('../logo.jpeg');
const logout_logo = require('../download (1).png');

const Header = () => {
    const [preparation, setPreparation] = useState({});
    const [cmd, setCmd] = useState({});

    useEffect(() => {
        checkValidLogin();
        getStoresHistoryDates();
    }, []);

    async function checkValidLogin() {
        let token = localStorage.getItem("amira-token");
        if (!token) {
            window.location.href = '/';
        }
    }

    async function logout() {
        localStorage.removeItem("amira-token");
        window.location.href = '/';
    }

    async function getStoresHistoryDates() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/file-history/data`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    }
                }
            );
            console.log(response.data);
            const data = response.data;
            if (data.status === "Success") {
                setPreparation(data.data.preparation || {});
                setCmd(data.data.cmd || {});
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark mx-5">
            <div className="container-fluid d-flex justify-content-start gap-4">
                <NavLink className="navlink navbar-brand mt-2 mt-lg-0" to={"/dashboard/"}>
                    <img src={imageSrc} alt='logo' />
                </NavLink>
                <div className="history-date-containers text-bold">
                    <div className="history-date">
                        Preparation Commandes: {preparation.date ? new Date(preparation.createdAt).toLocaleDateString() : "-"}
                    </div>
                    <div className="history-date">
                        CMD non soldées: {cmd.date ? new Date(cmd.createdAt).toLocaleDateString() : "-"}
                    </div>
                </div>
            </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="logout-box">
                    <a href="#" className="button rounded-pill" onClick={logout}>
                        <img src={logout_logo} alt="logout" />
                        <div className="logout">LOGOUT</div>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Header;
