import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from "./header";
import axios from "axios";
import { NavLink } from 'react-router-dom';
import Pagination from "../common/pagination";
// import QRCode from "react-qr-code";
import bwipjs from 'bwip-js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ProductTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storelist: [],
            page: 1,
            setPage: "",
            setStores: "",
            store_name: "",
            store_code: "",
            isModalOpen: false,
            newDoc: "",
            newExt: "",
            newDate2: "",
            search: "",
            show_archived: false,
            loader: true,
            sort: "DESC",
            sort_by: "en_num_doc",
            isPreparationModel: false,
            isModalDropdownVisible: false,
            facturable: false,
            preparationNum: 0,
            preparationStatus: "",
            preparationData: [],
            isDropdownVisible: {},
            selectedItems: [],
            selectAll: false,
        }
        console.log(props.params);

        // console.log(props);
    }
    pageChange = (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        this.setState({
            page: Number(p),
            loader: true,
            storelist: []
        }, () => {
            this.getStores();
        });

    };
    // New method to open the modal
    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    // New method to close the modal
    closeModal = () => {
        this.setState({ isModalOpen: false, newDoc: "", newExt: "", newDate2: "" });
    };

    newSavCreate = async () => {
        try {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const hours = String(currentDate.getHours()).padStart(2, "0");
            const minutes = String(currentDate.getMinutes()).padStart(2, "0");

            const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/store/create`,
                {
                    "ac_raison_sociale": this.state.store_name,
                    "cl_code": this.state.store_code,
                    "en_num_doc": this.state.newDoc,
                    "en_num_ext": this.state.newExt,
                    "en_date2": this.state.newDate2,
                    "en_date_doc": formattedDate,
                    "etat_lib": 'sav',
                    "facturable": this.state.facturable
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            if (response.data.status === "Success") {
                this.getStores();
                this.closeModal();
            } else {
                alert("Failed to create. please try again");
            }
        } catch (err) {
            console.log(err);
        }
    }

    getStores = async (search = false) => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            console.log(productId);
            let query_string = '?search=';
            if (this.state.search && this.state.search !== "") {
                query_string += this.state.search
            }
            if (this.state.show_archived) { query_string += '&archive=true' };
            if (this.state.sort_by && this.state.sort_by !== "") { query_string += `&sort_by=${this.state.sort_by}&sort=${this.state.sort}`; }
            let response;
            // get localstorage value
            const localData = JSON.parse(localStorage.getItem("searchDataValue"));
            if (localData) {
                this.setState({
                    search: (localData && this.state.search === "") ? localData : ""
                })
            }
            // if (localData && localData.length > 0) {
            // query_string = '?search='+ localData;
            //  response = await axios.get(
            //     `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}${query_string}`,
            //     {
            //         headers: {
            //             Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
            //         },
            //     }
            // );
            //  } else {
            response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );
            // }

            console.log(response.data);
            if (response.data.status === "Success" && response.data.data.length > 0) {
                localStorage.removeItem("searchDataValue");
                this.setState({
                    storelist: response.data.data,
                    totalPage: response.data.totalPage,
                    store_code: response.data.data[0].cl_code,
                    store_name: response.data.data[0].ac_raison_sociale,
                    loader: false,
                })
            } else {
                this.setState({
                    storelist: response.data.data,
                    totalPage: 1,
                    store_code: productId, //response.data.data[0].cl_code,
                    // store_name: response.data.data[0].ac_raison_sociale,
                    loader: false,
                })
            }

            //   setCompanies({
            //     companies: data.data,
            //     totalPage: data.totalPage,
            //   });
        } catch (err) {
            console.log(err);
        }
    };

    getPreparationData = async (e) => {
        try {
            let page = 1;
            let preparationNum = e.target.dataset.num;
            let preparationStatus = e.target.dataset.status;
            console.log(preparationNum);
            console.log(preparationStatus);
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/preparation/get-data/${preparationNum}/${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data);
            if (response.data.status === "Success" && response.data.data.length > 0) {
                this.setState({
                    isPreparationModel: true,
                    preparationNum: preparationNum,
                    preparationStatus: preparationStatus,
                    preparationData: response.data.data
                })
            } else {
                this.setState({
                    isPreparationModel: true,
                    preparationNum: preparationNum,
                    preparationStatus: preparationStatus,
                    preparationData: []
                })
            }
        } catch (err) {
            console.log(err);
        }
    };

    getSVG = (value) => {
        let canvas = document.createElement("canvas");
        bwipjs.toCanvas(canvas, {
            bcid: "datamatrix", // Barcode type
            text: value, // Text to encode
            scale: 2, // 3x scaling factor
            height: 8, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center" // Always good to set this
        });

        return <div> <img src={canvas.toDataURL("image/png")} alt={value}></img> </div>
    }

    // OPEN DROPDOWN FOR ETAT LIB
    openStatusDropdown = (rowId) => {
        console.log("Row ID:", rowId);
        this.setState((prevState) => ({
            isDropdownVisible: {
                ...prevState.isDropdownVisible,
                [rowId]: !prevState.isDropdownVisible[rowId],
            },
        }));

    };
    closeStatusDropdown = () => {
        this.setState({ isDropdownVisible: false });
    };


    // Handle dropdown change
    handleDropdownChange = (e, rowId) => {
        const newStatus = e.target.value;
        console.log("newStatus:", newStatus);

        // Create a copy of the current storelist and update the specific product
        const updatedStorelist = this.state.storelist.map(product =>
            product.en_num_doc === rowId
                ? { ...product, etat_lib: newStatus }
                : product
        );

        // Update the state
        this.setState({ storelist: updatedStorelist });
    };


    // DROPDOWN VISIBLE
    openModalStatusDropdown = () => {
        this.setState({ isModalDropdownVisible: true });
    };

    closeModalStatusDropdown = () => {
        this.setState({ isModalDropdownVisible: false });
    };

    preparationDropdownChange = (e) => {
        const selectedStatus = e.target.value;
        console.log("selectedStatus:", selectedStatus);
        this.setState({ preparationStatus: selectedStatus });
    }


    handleStatusUpdateApi = async (e, rowId, statusName, isModelApi = true) => {
        console.log("Updating status for Row ID:", rowId);
        let requestBody = {};
        if (isModelApi) {
            console.log("product inside");
            requestBody = {
                en_num_doc: rowId,
                dataToUpdate: {
                    etat_lib: statusName
                },
            };
        }
        else {
            // Find the product in the storelist based on en_num_doc (rowId)
            const product = this.state.storelist.find(product => product.en_num_doc === rowId);
            console.log("product");
            if (!product) {
                console.error("Product not found");
                return;
            }
            // Get updated status
            requestBody = {
                en_num_doc: rowId,
                dataToUpdate: {
                    // etat_lib: updatedStatus, // Send the updated status
                    etat_lib: product.etat_lib, // Send the updated status
                },
            };
        }

        try {

            // Make the API request using axios
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/store/update-order/status`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            // Check the API response
            if (response.data.status === "Success") {
                alert("Status updated successfully");
                this.setState((prevState) => ({
                    isDropdownVisible: {
                        ...prevState.isDropdownVisible,
                        [rowId]: !prevState.isDropdownVisible[rowId],
                    },
                }));
                this.setState({ isModalDropdownVisible: false });
            } else {
                console.error("API Error: Operation not successful");
            }
        } catch (error) {
            console.error("API Error:", error);
            this.setState({ errorMessage: "Failed to update status" });
        }
    };

    handleCheckboxChange = (en_num_doc) => {
        this.setState((prevState) => {
            let selectedItems = [...prevState.selectedItems];
            if (selectedItems.includes(en_num_doc)) {
                selectedItems = selectedItems.filter((id) => id !== en_num_doc);
            } else {
                selectedItems.push(en_num_doc);
            }
            return { selectedItems };
        });
    };

    handleSelectAll = () => {
        this.setState((prevState) => {
            const selectAll = !prevState.selectAll;
            const selectedItems = selectAll ? prevState.storelist.map((product) => product.en_num_doc) : [];
            return { selectAll, selectedItems };
        });
    };

    handleBulkUpdate = async (archive= true) => {
        console.log("Updating selected items:", this.state.selectedItems);
        const confirmUpdate = window.confirm(`Are you sure you want to update ${this.state.selectedItems.length} items?`);
        if (confirmUpdate) {
            try {

                // Make the API request using axios
                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/store/update-order/status`,
                    {
                        en_num_doc: { $in: this.state.selectedItems },
                        dataToUpdate: {
                            archive: archive
                        },
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                        },
                    }
                );
    
                // Check the API response
                if (response.data.status === "Success") {
                    // alert("Status updated successfully");
                    toast.success(`Successfully updated ${this.state.selectedItems.length} items!`);
                    
                    this.setState({ selectedItems: [], selectAll: false });
                } else {
                    toast.error(`Failed to archive orders`);

                    // console.error("API Error: Operation not successful");
                }
            } catch (error) {
                console.error("API Error:", error);
                toast.error(`Failed to archive orders`);

                this.setState({ errorMessage: "Failed to update status" });
            }

        }
        // Implement your bulk update logic here
    };

    componentDidMount() {
        this.getStores();
    }
    render() {
        return (
            <div>
                <Header />
                <div className="mx-5">
                <ToastContainer position="top-right" autoClose={3000} />
                    <div className="my-2 d-flex justify-content-between">
                        <div>
                            <h6 className="mt-3 ms-2">Code client : {this.state.store_code}</h6>
                            <h6 className="mt-3 ms-2">Client :  {this.state.store_name}</h6>
                        </div>
                        <div>
                            <NavLink className="navlink" to={"/scan/" + this.state.store_code}>
                                <button type="button" className="btn bg-secondary me-2 border rounded text-white px-5 py-auto">Numérisation</button>
                            </NavLink>
                            <button type="button" className="btn bg-secondary me-2 border rounded text-white px-5 py-auto" onClick={this.openModal}>S.A.V</button>
                            {/* <button type="button" className="btn me-2 border rounded-pill py-2 px-5 text-black">Trouver</button> */}
                            <input type="search" className="search-input btn me-2 border rounded-pill py-2  text-black " onChange={(e) => { this.setState({ search: e.target.value, page: 1 }, () => { this.getStores(this.state.search) }) }} value={this.state.search} placeholder="Trouver..."></input>
                            <div className="p-1 d-flex justify-content-between">
                                <label className="d-flex gap-2 pt-1"><input type="checkbox" checked={this.state.show_archived} onChange={(e) => {
                                    this.setState({ show_archived: !this.state.show_archived, page: 1 }, () => { this.getStores() })
                                }} />Afficher les archives</label>

                                <div className="clear-filter">
                                    {(this.state.search !== "" || this.state.show_archived || this.state.sort_by !== "en_num_doc") ?
                                        <span onClick={() => { this.setState({ search: "", show_archived: false, sort: "DESC", sort_by: "en_num_doc" }, () => { this.getStores() }) }}>Effacer le filtre</span>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div > */}
                    <div className="action-container">
                        {this.state.selectedItems.length !== 0 && <>
                            <button className="bg-dark-subtle border border-black btn me-2 px-5 py-auto rounded text-black" onClick={(e)=>{this.handleBulkUpdate(true)}}>
                                Archive
                            </button>
                            <button className="bg-dark-subtle border border-black btn me-2 px-5 py-auto rounded text-black" onClick={(e)=>{this.handleBulkUpdate(false)}}>
                                Unarchive
                            </button>
                        </>}
                    </div>
                    <table className="table mt-5">
                        <thead className="thead-dark mx-5">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={this.state.selectAll}
                                        onChange={this.handleSelectAll}
                                    />
                                </th>
                                <th className="text-center">Dashboard</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center">
                                        Date commande
                                        <div className="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i className="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'en_date_doc', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i className="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'en_date_doc', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Num cmd Danielli</th>
                                <th className="text-center">Stock(%)</th>
                                <th className="text-center">Datamatrix</th>
                                <th className="text-center">Num cmd Client</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        Date livraison souhaitée
                                        <div className="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i className="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'en_date2', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i className="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'en_date2', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Etat</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        Opération
                                        <div className="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i className="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'op_libel', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i className="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'op_libel', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>

                                </th>
                                <th className="text-center">Reservation</th>
                                <th className="text-center">Marque</th>
                                <th className="text-center">Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.storelist?.length === 0 ? ((this.state.loader) ?
                                    <tr><td colSpan={11}>
                                        <div className='text-center p-2'>
                                            <div className="loader1"><span></span><span></span><span></span><span></span><span></span></div>
                                        </div></td></tr> : <tr><td colSpan={11}>
                                            <div className="d-flex">
                                                <p className="mx-auto">Aucun Enregistrement Trouvé.</p></div></td></tr>
                                ) :
                                    this.state.storelist.map((product, index) => (
                                        <tr className={`${(product.archive || product.isScanned) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""} key={index}>
                                            <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.selectedItems.includes(product.en_num_doc)}
                                                    onChange={() => this.handleCheckboxChange(product.en_num_doc)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {new Date(product.createdAt).toLocaleDateString() + ' '}
                                                {new Date(product.createdAt).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center">
                                                {/* {product.en_date_doc} */}
                                                {new Date(product.en_date_doc).toLocaleDateString() + ' '}
                                                {new Date(product.en_date_doc).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center"><div key={index + "en_num"} style={{ color: "blue", cursor: "pointer" }}
                                                onClick={this.getPreparationData} data-status={product.etat_lib} data-num={product.en_num_doc}>
                                                {product.en_num_doc}</div>
                                            </td>
                                            <td className="text-center">
                                                {(product.avail_stock > 0 && product.avail_stock) ? product.avail_stock.toFixed(2) + "%" : (product.avail_stock != null && product.avail_stock > -1) ? product.avail_stock : 0}
                                            </td>
                                            <td className="text-center">
                                                {this.getSVG(product.en_num_doc)}
                                            </td>
                                            <td className="text-center">{product.en_num_ext}</td>
                                            <td className="text-center">
                                                {/* {product.en_date2} */}
                                                {new Date(product.en_date2).toLocaleDateString() + ' '}
                                                {new Date(product.en_date2).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center">
                                                <div className="align-items-center d-flex gap-2 justify-content-center">

                                                    {!this.state.isDropdownVisible[product.en_num_doc] ? (
                                                        <>
                                                            {product.etat_lib !== '' ? product.etat_lib : ''}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                width="25"
                                                                height="25"
                                                                viewBox="0 0 50 50"
                                                                onClick={() => this.openStatusDropdown(product.en_num_doc)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <path d="M 46.574219 3.425781 C 45.625 2.476563 44.378906 2 43.132813 2 C 41.886719 2 40.640625 2.476563 39.691406 3.425781 C 39.691406 3.425781 39.621094 3.492188 39.53125 3.585938 C 39.523438 3.59375 39.511719 3.597656 39.503906 3.605469 L 4.300781 38.804688 C 4.179688 38.929688 4.089844 39.082031 4.042969 39.253906 L 2.035156 46.742188 C 1.941406 47.085938 2.039063 47.453125 2.292969 47.707031 C 2.484375 47.898438 2.738281 48 3 48 C 3.085938 48 3.171875 47.988281 3.257813 47.964844 L 10.746094 45.957031 C 10.917969 45.910156 11.070313 45.820313 11.195313 45.695313 L 46.394531 10.5 C 46.40625 10.488281 46.410156 10.472656 46.417969 10.460938 C 46.507813 10.371094 46.570313 10.308594 46.570313 10.308594 C 48.476563 8.40625 48.476563 5.324219 46.574219 3.425781 Z M 45.160156 4.839844 C 46.277344 5.957031 46.277344 7.777344 45.160156 8.894531 C 44.828125 9.222656 44.546875 9.507813 44.304688 9.75 L 40.25 5.695313 C 40.710938 5.234375 41.105469 4.839844 41.105469 4.839844 C 41.644531 4.296875 42.367188 4 43.132813 4 C 43.898438 4 44.617188 4.300781 45.160156 4.839844 Z M 5.605469 41.152344 L 8.847656 44.394531 L 4.414063 45.585938 Z"></path>
                                                            </svg>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <select
                                                                value={product.etat_lib}
                                                                className="form-control"
                                                                onChange={(e) => this.handleDropdownChange(e, product.en_num_doc)}
                                                            >
                                                                <option value="Non Livrée">Non Livrée</option>
                                                                <option value="A préparer">A préparer</option>
                                                                <option value="picked">Picked</option>
                                                                <option value="Dispatched">Dispatched</option>
                                                                <option value="sav">S.A.V</option>
                                                            </select>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                                                                onClick={(e) => this.handleStatusUpdateApi(e, product.en_num_doc, product.etat_lib, false)} style={{ cursor: "pointer" }}>
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
                                                                    fill="#0F0F0F"></path>
                                                            </svg>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                width="25"
                                                                height="25"
                                                                viewBox="0 0 50 50"
                                                                onClick={this.closeStatusDropdown}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                                                            </svg>
                                                        </>
                                                    )}

                                                </div>
                                            </td>
                                            <td className="text-center">{product.op_libel}</td>
                                            <td className="text-center">{product.res}</td>
                                            <td className="text-center">{product.marque_logotee}</td>
                                            <td className="text-center">{product.awb}</td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                    {/* </div> */}
                </div>

                <div className='mt-2 pagination justify-content-center'>
                    {/* <div className='page-item'> */}
                    <Pagination
                        page={this.state.page}
                        pageChange={this.pageChange}
                        totalPage={this.state.totalPage}
                    />
                    {/* </div> */}
                </div>

                {/* The Modal */}
                <div className={`tsw modal ${this.state.isModalOpen ? "d-block" : ""}`} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ajouter un nouveau S.A.V</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); this.newSavCreate() }}>
                                <div className="modal-body">
                                    {/* Add content for the modal here */}
                                    <p><b>Code client: </b> {this.state.store_code}</p>
                                    <p><b>Client: </b> {this.state.store_name}</p>
                                    <div className="form-data text-center pt-2 pb-2">
                                        <input type="text" placeholder="en_num_doc" required className="mb-2 input" onChange={(e) => this.setState({ newDoc: e.target.value })}></input>
                                        <input type="text" placeholder="en_num_ext" required className="mb-2 input" onChange={(e) => this.setState({ newExt: e.target.value })}></input>
                                        <input type="datetime-local" placeholder="en_date2" required className="mb-2 en-date" onChange={(e) => this.setState({ newDate2: e.target.value })}></input>
                                        <div>
                                          <label className="mb-2" htmlFor="facturable" ><input type="checkbox" name="facturable" onChange={(e)=> this.setState({facturable: e.target.checked}) } /> Facturable </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-secondary">Save</button>
                                    {/* <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Close</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {this.state.isModalOpen && <div className="modal-backdrop show" style={{ zIndex: "1050", backgroundColor: "rgba(0, 0, 0, 0.5)" }}></div>}

                {/* The Preparation Modal */}
                <div className={`tsw preparation modal ${this.state.isPreparationModel ? "d-block" : ""}`} tabIndex="-2" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title">Ajouter un nouveau S.A.V</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-body p-0">
                                {/* Add content for the modal here */}
                                <div className="model-status align-items-center d-flex gap-2 justify-content-between" >
                                    <p className="pb-4"><b>Num cmd Daniellis: </b> {this.state.preparationNum} </p>

                                    <div className="align-items-center d-flex gap-2 justify-content-center pb-4">

                                        {!this.state.isModalDropdownVisible ? (
                                            <>
                                                {this.state.preparationStatus !== 'sav' ? this.state.preparationStatus : ''}
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50" onClick={this.openModalStatusDropdown}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <path
                                                        d="M 46.574219 3.425781 C 45.625 2.476563 44.378906 2 43.132813 2 C 41.886719 2 40.640625 2.476563 39.691406 3.425781 C 39.691406 3.425781 39.621094 3.492188 39.53125 3.585938 C 39.523438 3.59375 39.511719 3.597656 39.503906 3.605469 L 4.300781 38.804688 C 4.179688 38.929688 4.089844 39.082031 4.042969 39.253906 L 2.035156 46.742188 C 1.941406 47.085938 2.039063 47.453125 2.292969 47.707031 C 2.484375 47.898438 2.738281 48 3 48 C 3.085938 48 3.171875 47.988281 3.257813 47.964844 L 10.746094 45.957031 C 10.917969 45.910156 11.070313 45.820313 11.195313 45.695313 L 46.394531 10.5 C 46.40625 10.488281 46.410156 10.472656 46.417969 10.460938 C 46.507813 10.371094 46.570313 10.308594 46.570313 10.308594 C 48.476563 8.40625 48.476563 5.324219 46.574219 3.425781 Z M 45.160156 4.839844 C 46.277344 5.957031 46.277344 7.777344 45.160156 8.894531 C 44.828125 9.222656 44.546875 9.507813 44.304688 9.75 L 40.25 5.695313 C 40.710938 5.234375 41.105469 4.839844 41.105469 4.839844 C 41.644531 4.296875 42.367188 4 43.132813 4 C 43.898438 4 44.617188 4.300781 45.160156 4.839844 Z M 5.605469 41.152344 L 8.847656 44.394531 L 4.414063 45.585938 Z">
                                                    </path>
                                                </svg>

                                            </>
                                        ) : (
                                            <>
                                                <select
                                                    value={this.state.preparationStatus}
                                                    className="form-control"
                                                    onChange={this.preparationDropdownChange}
                                                >
                                                    <option value="Non Livrée">Non Livrée</option>
                                                    <option value="A préparer">A préparer</option>
                                                    <option value="picked">Picked</option>
                                                    <option value="Dispatched">Dispatched</option>
                                                    <option value="sav">S.A.V</option>
                                                </select>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                                                    onClick={(e) => this.handleStatusUpdateApi(e, this.state.preparationNum, this.state.preparationStatus, true)} style={{ cursor: "pointer" }}>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
                                                        fill="#0F0F0F"></path>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50"
                                                    onClick={this.closeModalStatusDropdown} style={{ cursor: 'pointer' }}>
                                                    <path
                                                        d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z">
                                                    </path>
                                                </svg>
                                            </>
                                        )}

                                    </div>
                                </div>

                                <div className="preparation-table">
                                    <table className="table">
                                        <thead className="thead-dark mx-5">
                                            <tr>
                                                <th className="text-left">li_designation</th>
                                                <th className="text-center">li_qte_reste</th>
                                                <th className="text-center">dp_stock</th>
                                                <th className="text-center">reference</th>
                                                <th className="text-center">size</th>
                                                <th className="text-center">location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.preparationData?.length === 0 ? ((this.state.loader) ?
                                                <tr><td colSpan={3}>
                                                    <div className='text-center p-2'>
                                                        <div className="loader1"><span></span><span></span><span></span><span></span><span></span></div>
                                                    </div></td></tr> : <tr><td colSpan={3}>
                                                        <div className="d-flex">
                                                            <p className="mx-auto">Aucun Enregistrement Trouvé.</p></div></td></tr>
                                            ) :
                                                this.state.preparationData.map((product, index) => (
                                                    <tr className={`${(product.archive) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""} key={index}>
                                                        <td className="text-left">{product.li_designation}</td>
                                                        <td className="text-center">{product.li_qte_reste}</td>
                                                        <td className="text-center">{product.dp_stock}</td>
                                                        <td className="text-center">{product.ar_reference}</td>
                                                        <td className="text-center">{product.tl_taille}</td>
                                                        <td className="text-center">{product.ar_lieu_stock}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center p-0 border-0">
                                {/* <button type="submit" className="btn btn-secondary">Save</button> */}
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    this.setState({ isPreparationModel: false, preparationData: [], preparationNum: 0, preparationStatus: "" }, () => {
                                        this.getStores();
                                    })
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isPreparationModel && <div className="modal-backdrop show" style={{ zIndex: "1050", backgroundColor: "rgba(0, 0, 0, 0.5)" }}></div>}
            </div>
        );
    }
}

export default ProductTable;
